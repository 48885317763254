import {renderToString} from 'react-dom/server';
import L from 'leaflet';
import {JsonView, allExpanded, defaultStyles} from 'react-json-view-lite';
import {alertService} from '../services';
import {availableModes} from '.';
import 'react-json-view-lite/dist/index.css';

export function addDynamicStyles(combinedStyles, dynamicStyles, featureProperties) {
    let styles = {};

    Object.keys(dynamicStyles).forEach(key => {
        const featureValue = featureProperties?.[key];
        // we have a matching value
        if (undefined !== featureValue) {
            let condition = 'eq';
            let dynamicStyleValues = [];
            let dynamicStyleKeys = [];
            let style = {};

            // dynamic styles with conditions, that have numbers as keys
            if ('values' in dynamicStyles[key]) {
                if ('condition' in dynamicStyles[key]) { // there should always be a condition, better be safe
                    condition = dynamicStyles[key]['condition'];
                }
                dynamicStyleValues = dynamicStyles[key]['values'];
                // order number/float values based on condition to check them in the right order
                dynamicStyleKeys = Object.keys(dynamicStyleValues).map(value => parseFloat(value)).sort();
                if (condition === 'gte') {
                    dynamicStyleKeys.reverse(); // highest value needs to be checked first for >=
                }
            // dynamic styles without condition
            } else {
                dynamicStyleValues = dynamicStyles[key];
                dynamicStyleKeys = Object.keys(dynamicStyleValues);
            }

            // loop through given keys (i.e. values) from dynamicStyles and attempt to match the condition
            for (const dynamicStyleKey of dynamicStyleKeys) {
                const dynamicStyle = dynamicStyleValues[dynamicStyleKey];
                switch (condition) {
                    case 'eq':
                        if (featureValue == dynamicStyleKey) {
                            style = dynamicStyle;
                        }
                        break;
                    case 'gte':
                        if (featureValue >= dynamicStyleKey) {
                            // console.log('we have a match', valuesKey, values[valuesKey]);
                            style = dynamicStyle;
                        }
                        break;
                    case 'lte':
                        if (featureValue <= dynamicStyleKey) {
                            style = dynamicStyle;
                        }
                        break;
                    case 'incl':
                        if (true === featureValue.includes(dynamicStyleKey)) {
                            style = dynamicStyle;
                        }
                        break;
                }
            }

            styles = {
                ...styles,
                ...style,
            };
        }
    });

    // only set when we have a value
    if (undefined !== styles.color) combinedStyles.color = styles.color;
    if (undefined !== styles.fillColor) combinedStyles.fillColor = styles.fillColor;
    if (undefined !== styles.fillOpacity) combinedStyles.fillOpacity = styles.fillOpacity;
    if (undefined !== styles.opacity) combinedStyles.opacity = styles.opacity;
    if (undefined !== styles.radius) combinedStyles.radius = styles.radius;
    if (undefined !== styles.weight) combinedStyles.weight = styles.weight;

    return combinedStyles;
}

export function copyDeep(object) {
    return JSON.parse(JSON.stringify(object));
}

export function copyToClipboard(value, onSuccess = null) {
    navigator.clipboard
        .writeText(value)
        .then(() => {
            alertService.success(`<strong>${value}</strong> copied to clipboard.`);
            if (onSuccess !== null) {
                onSuccess();
            }
        })
        .catch(() => {
            alertService.error('Something went wrong with copying data to clipboard');
        });
}

export function createMarkerPopup(marker, setUserSelection) {
    const coordinates = marker.getLatLng();
    const coordinatesString = `${coordinates.lat},${coordinates.lng}`;

    const elementUl = document.createElement('ul');
    elementUl.setAttribute('class', 'list-unstyled');

    // search nearby
    const elementLiSearch = document.createElement('li');
    const elementLiSearchLink = document.createElement('a');
    elementLiSearchLink.setAttribute('href', '');
    elementLiSearchLink.textContent = 'Search around here';
    elementLiSearchLink.onclick = e => {
        e.preventDefault();
        setUserSelection({
            'data': {
                'latitude': coordinates.lat,
                'longitude': coordinates.lng,
            },
            'id': coordinatesString,
            'display_name': coordinatesString,
            'type': 'Coordinates',
        });
        marker.closePopup();
    };
    elementLiSearch.appendChild(elementLiSearchLink);
    elementUl.appendChild(elementLiSearch);

    // launch Google Maps
    const elementLiGmaps = document.createElement('li');
    const elementLiGmapsLink = document.createElement('a');
    elementLiGmapsLink.setAttribute('href', `https://www.google.com/maps/search/?api=1&query=${coordinatesString}`);
    elementLiGmapsLink.setAttribute('target', '_blank');
    elementLiGmapsLink.textContent = 'Open in Google Maps';
    elementLiGmaps.appendChild(elementLiGmapsLink);
    elementUl.appendChild(elementLiGmaps);

    return elementUl;
}

export function createMetadataPopup(title, data) {
    return L.popup({
        'maxHeight': 400,
        'maxWidth': 620,
        'minWidth': 240,
    })
        .setContent(getMetadataPopup(title, data));
}

export function filter(formValues, data) {
    let filteredData = data;
    Object.keys(formValues).forEach(localFormValueKey => {
        const formValue = formValues[localFormValueKey];
        // identify the right column, either a first or a second level property key
        const isNextLevel = formValue.keyNextLevel !== '';
        filteredData = filteredData.filter(data => {
            // hit the right data based on the selected level
            if (isNextLevel === true) {
                // this is made for data arrays like "connectionPoints"
                const connectionPoints = JSON.parse(data.properties[formValue.key]);
                const matchedConnectionPoints = connectionPoints.filter(connectionPoint => {
                    return filterCheck(formValue.condition, connectionPoint[formValue.keyNextLevel], formValue.value);
                });

                return matchedConnectionPoints.length > 0;
            } else {
                return filterCheck(formValue.condition, data.properties[formValue.key], formValue.value);
            }
        });
    });

    return filteredData;
}

export function filterCheck(condition, data, value) {
    if (null === data) {
        return false;
    }

    // e.g. codesPostaux is a one-element array
    if (true === Array.isArray(data)) {
        data = data.pop();
    }

    // e.g. substations on high voltage lines
    if ('object' === typeof data && true === 'code' in data) {
        data = data.code;
    }

    switch (condition) {
        default:
        case 'eq':
            return data == value; // it is important to not type-compare since int, float, string can be compared
        case 'gte':
            return data >= value;
        case 'lte':
            return data <= value;
        case 'incl':
            if ('string' === typeof data) {
                return true === data.toLowerCase().includes(value.toLowerCase());
            }
            if (true === Array.isArray(data)) {
                return true === data.includes(value);
            }
        case 'not':
            return data != value;
    }
}

export function getAvailableModes(country) {
    const countryAvailableModes = {};
    for (const [key, value] of Object.entries(availableModes)) {
        if (country in value.activeLayers) {
            countryAvailableModes[key] = value;
        }
    }

    return countryAvailableModes;
}

// returns the average latitude and longitude for an array of coordinates
export function getCenter(coordinates) {
    const [y, x] = coordinates.reduce((sum, coord) => {
        sum[0] += parseFloat(coord[0]);
        sum[1] += parseFloat(coord[1]);
        return sum;
    }, [0, 0]);

    return [y / coordinates.length, x / coordinates.length];
}

// get array of coordinates from geometry, the one and only for polygons or the one with the most elements for MultiPolygon
export function getCoordinatesForGeometry(geometry) {
    if (geometry.type === 'MultiPolygon') {
        let maxElementsCount = -1;
        let maxElementsKey;
        for (let i = 0; i < geometry.coordinates.length; i++) {
            const elementsCount = geometry.coordinates[i]?.[0].length;
            if (maxElementsCount < elementsCount) {
                maxElementsCount = elementsCount;
                maxElementsKey = i;
            }
        }
        return geometry.coordinates[maxElementsKey]?.[0];
    }

    return geometry.coordinates[0];
}

export function getGeoJsonFromElements(elements) {
    const geoJson = [];
    elements.forEach(element => {
        if (undefined !== element) {
            geoJson.push({
                'geometry': element.geometry,
                'id': element._id,
                'properties': {
                    'display_name': element.display_name, // @TODO: API should send this in metadata
                    ...element.metadata,
                },
                'type': 'Feature',
            });
        }
    });

    return geoJson;
}

export function getLatLng(string, separator = ',') {
    let coordinates = string.split(separator).map(coordinate => {
        return parseFloat(coordinate.trim());
    });

    // didn't find comma-separated coordinates, try semicolon
    if (coordinates.length !== 2 || coordinates.includes(NaN)) {
        if (separator !== ';') {
            return getLatLng(string, ';');
        }

        return null;
    }

    return coordinates;
}

// take customization settings from backend and fill in missing default values
export function getLayerSettings(layer) {
    let country = layer?.properties?.country || 'FR';
    let scope = layer?.properties?.scope || 'search';
    let style = {
        'active': layer?.customization?.active,
        'default': {
            'color': layer?.customization?.default?.color || '#E3256B', // border color
            'fillColor': layer?.customization?.default?.fillColor || '#FD7F4F', // shape color
            'fillOpacity': layer?.customization?.default?.fillOpacity || 1, // shape opacity
            'opacity': layer?.customization?.default?.opacity || 1, // border opacity
            'radius': layer?.customization?.default?.radius || 5,
            'weight': layer?.customization?.default?.weight || 1, // border width
        },
        'dynamic': layer?.customization?.dynamic,
    };
    let type = layer?.properties?.type || 'default';

    return {
        'country': country,
        'scope': scope,
        'style': style,
        'type': type,
    };
}

export function getMetadataPopup(title, data) {
    return renderToString(
        <div>
            <h5>{title}</h5>
            <pre>{jsonPrettify(data)}</pre>
        </div>
    );
}

// get dynamic style for feature
export function getStyle(layer, feature) {
    // apply default styles
    let style = {...layer.style.default};

    // legacy for plot layers, @TODO: remove once backend sends aggregated max rating
    if ('plot' === layer.type && undefined !== layer.style?.dynamic?.NUM_rating) {
        // we need this custom code since we have ratings on a substation-level, get the highest substation rating
        const substations = 'substations' in feature.properties ? feature.properties['substations'] : [];
        let rating = 0;


        // get the biggest rating of the provided substations
        substations.forEach(substation => {
            if ('NUM_rating' in substation && substation['NUM_rating'] > rating) {
                rating = substation['NUM_rating'];
            }
        });

        return addDynamicStyles(style, layer.style.dynamic, {'NUM_rating': rating});
    }

    // apply dynamic styles
    if (undefined !== layer.style.dynamic) {
        return addDynamicStyles(style, layer.style.dynamic, feature.properties);
    }

    // return default styles
    return style;
}

export function inverseCoordinates(coordinates) {
    return coordinates.map(coordinate => [coordinate[1], coordinate[0]]);
}

export function isObjectEmpty(object) {
    return 0 === Object.keys(object).length;
}

export function jsonPrettify(json) {
    return <JsonView data={json} shouldExpandNode={allExpanded} style={defaultStyles} />;
}

export function numberPrettify(number) {
    return new Intl.NumberFormat('fr-FR').format(number);
}

export function resetFeatureStyle(layerRef) {
    // we don't always have a reference, e.g. for react native's municipality polygons
    if (null !== layerRef) {
        // reset styles of all features
        layerRef.resetStyle();
    }
}
