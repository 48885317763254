import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import L from 'leaflet';
import {useMap} from 'react-leaflet/hooks';
import {BoxSelect, FilterLine} from '.';
import {filter, getStyle, markerIconInstallation, MODE_GRAND_COMPTE, MODE_PROSPECTION} from '../utils';

export function SearchDataLayers({filtersShow, onFeatureSelect, onFeaturesSelect, reloadUserSelection, searchIsLoading}) {
    const map = useMap();
    const layers = useSelector(state => state.layers.value.search);
    const layersSearchAdd = useSelector(state => state.layers.value.searchAdd);
    const userMode = useSelector(state => state.user.value.mode);
    const userSubstation = useSelector(state => state.user.value.substation);

    map.boxSelect.enable();
    // @TODO: understand and fix "boxselectend" firing dozens of times instead once
    map.on("boxselectend", onFeaturesSelect);

    const [dataLayers, setDataLayers] = useState([]);

    // layer has been updated from higher components, render result
    useEffect(() => {
        reset();

        // render layers
        const localDataLayers = [];
        if (layers !== null) {
            layers.forEach(layer => {
                // add or update data
                if (layer.data.length > 0) {
                    layer = initLayer(layer);
                    const layerRef = addToMap(layer);
                    localDataLayers.push({
                        'layer': layer,
                        'layerRef': layerRef,
                    });

                    if (layer.areFeaturesHidden === true) {
                        layerRef.remove();
                    }
                }
            });
        }

        setDataLayers(localDataLayers);

        return () => {
            setDataLayers([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layers]);

    // add a new layer to data layers to allow for adding data on top of existing layers, will be lost once search data layers are reset
    useEffect(() => {
        if (layersSearchAdd !== null) {
            const localDataLayers = dataLayers;
            const layer = initLayer(layersSearchAdd);
            localDataLayers.push({
                'layer': layer,
                'layerRef': addToMap(layer),
            });
            setDataLayers(localDataLayers);
        }

        return () => {
            setDataLayers([]);
        };
    }, [layersSearchAdd]);

    // layerData is already contained in layer, but we cannot always modify layer.data, i.e. when applying filter() on load
    const addToMap = layer => {
        const layerRef = L.geoJSON(layer.data, {
            'onEachFeature': (feature, leafletLayer) => {
                leafletLayer.on({
                    'click': event => onFeatureSelect(layer, feature, event, layerRef),
                });
            },
            // style points
            'pointToLayer': (geoJsonPoint, center) => {
                // show default marker for unscoped layers with Point data
                if (undefined === layer.scope) {
                    return new L.Marker(center);
                }

                // show custom marker if requested
                if ('installation' === layer.type && true !== layer?.customization?.useCustomMarker) {
                    const style = getStyle(layer, layer.data[0]);
                    return new L.Marker(center, {
                        'icon': markerIconInstallation(style.fillColor),
                    });
                }

                return L.circleMarker(center, getStyle(layer, geoJsonPoint));
            },
            // style polygons
            'style': feature => {
                return getStyle(layer, feature);
            },
        }).addTo(map);

        return layerRef;
    };

    // add additional data to layer for filtering etc.
    const initLayer = layer => {
        let defaults = {
            'condition': '',
            'key': '',
            'keyNextLevel': '',
            'value': '',
        };
        let isFilterOpen = false;
        let layerData = layer.data;
        // ensure we have a substation, the high voltage lines layer, and at least one element with a code property
        if ([MODE_GRAND_COMPTE, MODE_PROSPECTION].includes(userMode) &&
            userSubstation !== null &&
            'highVoltageLine' === layer.type) {
            defaults.condition = 'eq';
            defaults.key = 'substations';
            defaults.value = userSubstation.code;
            isFilterOpen = true;
            layerData = filter({0: defaults}, layerData);
        }

        return {
            ...layer,
            'data': layerData,
            'defaults': defaults,
            'isFilterOpen': isFilterOpen,
        };
    };

    // remove all existing layers, this also switches the eye icon to hidden
    const reset = () => {
        dataLayers.forEach(dataLayer => {
            dataLayer.layerRef.remove();
        });
    };

    // data layer has been filtered from embedded filter line component, render result
    const updateDataLayer = updatedDataLayer => {
        updatedDataLayer.layerRef.remove(); // remove layer from map

        setDataLayers(dataLayers.map(localDataLayer => {
            // update given layer
            if (localDataLayer.layer._id === updatedDataLayer.layer._id) {
                return {
                    'layer': updatedDataLayer.layer,
                    'layerRef': addToMap(updatedDataLayer.layer), // put layer back on map
                };
            }

            // don't touch others
            return localDataLayer;
        }));
    };

    if (filtersShow === true && searchIsLoading === false && dataLayers.length > 0) {
        return (
            <div className="filter">
                <ul className="list-group list-group-flush">
                    {dataLayers.sort((a, b) => a.layer.name > b.layer.name ? 1 : -1).map((dataLayer, key) =>
                        <FilterLine
                            key={key}
                            layer={dataLayer.layer}
                            layerRef={dataLayer.layerRef}
                            resetDataLayer={() => {
                                reloadUserSelection();
                            }}
                            updateDataLayer={updateDataLayer}
                        />
                    )}
                </ul>
            </div>
        );
    }
}
